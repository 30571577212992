<script>
import {mapGetters} from "vuex";

export default {
  name: "ObserverCount",
  computed: {
    ...mapGetters('game', ['roomInfo']),
    count() {
      if (!this.roomInfo) {
        return 0;
      }

      return this.roomInfo.observeUserCnt || 0;
    }
  }
}
</script>

<template>
  <div class="text-center ms-3">
    <div>
      <img src="@/assets/v2/svg/ico_user.svg" alt="게임 관전자 수">
    </div>
    <div class="txt-spectator">관전자 ({{ count }})</div>
  </div>
</template>

<style scoped lang="scss">

</style>